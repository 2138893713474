import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import theme from '../../theme'
import Div from '../../elements/Div'
import Header from '../../elements/Header'
import Nav from '../../elements/Nav'
import H1 from '../../elements/H1'

import Layout from '../../components/layout'
import TextButton from '../../components/TextButton'
import Flex from '../../components/Flex'
import Logo from '../../components/Logo'

export default () => {
  const [gradients, setGradients] = useState([])

  useEffect(() => {
    try {
      const previousVotes = JSON.parse(localStorage.getItem('gradientVotes'))

      setGradients(previousVotes)
    } catch (e) {}
  }, [])

  return (
    <Layout>
      <Nav>
        <Link to="/">
          <Div p={3}>
            <Logo />
          </Div>
        </Link>
      </Nav>

      <Header px={3} py={3} textAlign="center">
        <H1 my={0} fontSize={2}>
          Gradients
        </H1>
      </Header>
      <Div maxWidth="72em" mx="auto">
        <Div
          display="grid"
          gridTemplateColumns="repeat(auto-fill, minmax(20%,1fr))"
          px={3}
        >
          {gradients.map((gradient, i) => (
            <Link to={`/gradients/votes/share?id=${gradient.id}`}>
              <div
                key={i}
                css={{
                  height: 120,
                  width: '100%',
                  ...gradient
                }}
              />
            </Link>
          ))}
        </Div>
      </Div>
    </Layout>
  )
}
